import {
	Action,
	FETCH_BESTROI_ITEMS,
    FETCH_BESTROI_ITEMS_FAIL,
    FETCH_TRENDING_ITEMS,
    FETCH_TRENDING_ITEMS_FAIL
} from '../utils/consts';


export interface MarketInsights {
	"trending": any[],
    "bestROI": any[]
}

const initialState: MarketInsights = {
    trending: [],
    bestROI: []
};

const marketReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case FETCH_TRENDING_ITEMS:
			return {
				...state, trending:payload
            };
		case FETCH_TRENDING_ITEMS_FAIL:
		case FETCH_BESTROI_ITEMS:
            return {
				...state, bestROI:payload
            };
		case FETCH_BESTROI_ITEMS_FAIL:
		default:
			return state;
	}
};

export default marketReducer;
