import { Action, SET_MENU } from '../utils/consts';

const initialState: number = 0;

const menuReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_MENU:
			return payload;
		default:
			return state;
	}
};

export default menuReducer;
