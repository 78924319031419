import { combineReducers } from 'redux';
import alert from './alert';
import register from './register';
import menu from './menu';
import supplier from './supplier';
import customer from './customer'
import staff from './staff';
import reports from './reports';
import market from './market';
import inventory from './inventory';
export default combineReducers({
	alert,
	register,
	menu,
	supplier,
	customer,
	staff,
	reports,
	market,
	inventory
});
