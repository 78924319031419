//Authentication is handled here, please refer to auth.js in utils for request logic

import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	LOAD_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	VERIFICATION_SUCCESS,
	VERIFICATION_FAIL,
	LOGOUT,
	Action,
	TOKEN_KEY,
	CHANGE_STORE,
	ADD_REMINDER,
	ADD_REMINDER_FAIL,
	DELETE_REMINDER,
	DELETE_REMINDER_FAIL,
	DAY_TOTALS,
	DAY_TOTALS_FAIL,
	UPDATE_REMINDER,
	UPDATE_REMINDER_FAIL
} from '../utils/consts';

const initialState = {
	token: localStorage.getItem(TOKEN_KEY),
	isAuthenticated: null,
	user: {},
	verified: null,
	loading: null,
	selectedStoreIndex: 0,
	stores: [{}],
	todaySale:0,
	todayPurchase:0
};

const registerReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case USER_LOADED:
		case LOGIN_SUCCESS:
			if (type === LOGIN_SUCCESS)
				localStorage.setItem(TOKEN_KEY, payload.token);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
				verified: payload.confrimed,
			};
		case REGISTER_SUCCESS:
			localStorage.setItem(TOKEN_KEY, payload.token);
			return {
				...state,
				...payload,
				isAuthenticated: true,
				loading: false,
			};
		case VERIFICATION_SUCCESS:
			localStorage.removeItem(TOKEN_KEY);
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				verified: true,
			};
		case VERIFICATION_FAIL:
			return {
				...state,
				isAuthenticated: false,
				loading: false,
				verified: false,
			};
		case REGISTER_FAIL:
		case LOGIN_FAIL:
		case LOAD_FAIL:
		case LOGOUT:
			localStorage.removeItem(TOKEN_KEY);
			return {
				...state,
				...payload,
				isAuthenticated: false,
				loading: false,
				verified: null,
			};
		case CHANGE_STORE:
			return {
				...state,
				...payload
			}

		case ADD_REMINDER:
			state.stores[state.selectedStoreIndex] = payload.store
			return {
				...state,
			};
		case DELETE_REMINDER:
			state.stores[state.selectedStoreIndex] = payload.store
			return {
				...state,
			};
		case UPDATE_REMINDER:
			state.stores[state.selectedStoreIndex] = payload.store
			return {
				...state,
			};
		case ADD_REMINDER_FAIL:
		case DELETE_REMINDER_FAIL:
		case UPDATE_REMINDER_FAIL:
		case DAY_TOTALS:
				return {...state,totalSale:payload.sell,totalPurchase:payload.purchase}
		case DAY_TOTALS_FAIL:
		default:
			return state;
	}
};

export default registerReducer;
