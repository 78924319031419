import {
	Action,
	FETCH_PURCHASEGST,
	FETCH_PURCHASEGST_FAIL,
	FETCH_SELLGST,
	FETCH_SELLGST_FAIL,
	FETCH_STAFFATTENDANCE,
	FETCH_STAFFATTENDANCE_FAIL,
} from '../utils/consts';

export interface Reports {
	sellGST: any[];
	purchaseGST: any[];
	GSTreclaim: any[];
	staffAttendance: any;
	financialReports: any;
}

const initialState: Reports = {
	sellGST: [],
	purchaseGST: [],
	GSTreclaim: [],
	staffAttendance: undefined,
	financialReports: undefined,
};

const reportReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case FETCH_SELLGST:
			return { ...state, sellGST: payload };
		case FETCH_SELLGST_FAIL:
		case FETCH_PURCHASEGST:
			return { ...state, purchaseGST: payload };
		case FETCH_PURCHASEGST_FAIL:
		case FETCH_STAFFATTENDANCE:
			return { ...state, staffAttendance: payload };
		case FETCH_STAFFATTENDANCE_FAIL:
		default:
			return state;
	}
};

export default reportReducer;
