import {
	Action,
	ADD_BATCH,
	ADD_BATCH_FAIL,
	ADD_ITEM,
	ADD_ITEM_FAIL,
	DELETE_BATCH,
	DELETE_BATCH_FAIL,
	FETCH_ITEMS,
	FETCH_ITEMS_FAIL,
	StoreItemDetails,
	UPDATE_BATCH,
	UPDATE_BATCH_FAIL
} from '../utils/consts';

// export interface SupplierPayload {
// 	name?: string, email?: string, gst?: string, address?: string, phone?: string
// }



const initialState: StoreItemDetails[] = [];

const inventoryReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case ADD_ITEM:
			console.log("itemReducer: ", payload);
			return state;
		case ADD_ITEM_FAIL:
		case FETCH_ITEMS:
			return payload.filter((obj:any)=>obj.itemData);
	    case FETCH_ITEMS_FAIL:
		case DELETE_BATCH:
			let Items = [...state]
			console.log(payload.deleteIndex, Items[payload.deleteIndex.item].batches,Items[payload.deleteIndex.item].batches.splice(payload.deleteIndex.batch, 1));
			
           // Items[payload.deleteIndex.item].batches = 
			Items[payload.deleteIndex.item].batches.splice(payload.deleteIndex.batch, 1)
			return Items;
		case DELETE_BATCH_FAIL:
		case ADD_BATCH:
			return state;
		case ADD_BATCH_FAIL:
		case UPDATE_BATCH:
			console.log(payload,state);
               let items = [...state]
			   items[payload.itemIndex].batches[payload.batchIndex] = payload.updatedBatch;
			return items;
			
		case UPDATE_BATCH_FAIL:
		default:
			return state;
	}
};

export default inventoryReducer;
