import { ChangeEvent, FormEvent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { login } from '../../actions/register';
import { Loading } from '../elements/Loading';
import { RootState } from '../../store';
import { ROUTES } from '../../utils/routing/routes';
// Material-ui
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import logo from '../../assests/logo.png';
import '../styles/Pages.css';

//Material-ui
//-------------------

const useStyles = makeStyles(theme => ({
	formCol1: {
		textAlign: 'center',
		fontSize: '1.4rem',
		fontWeight: '500',
	},
}));

//--------------------
const Login = () => {
	const classes = useStyles();
	const [formData, setFormData] = useState({
		userName: '',
		email: '',
		password: '',
		loading: false,
	});
	const isAuthenticated = useSelector<RootState, boolean>(
		state => state.register.isAuthenticated
	);
	const loading = useSelector<RootState, boolean>(
		state => state.register.loading
	);
	const dispatch = useDispatch();
	const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setFormData({ ...formData, loading: true });
		dispatch(login(formData));
	};


	if (loading && formData.loading) {
		return <Loading />;
	}

	if (isAuthenticated) {
		return <Redirect to={ROUTES.HOME} />;
	}

	return (
			<div  style={{display:'flex',justifyContent:'center',alignItems:'center',borderStyle:'solid',borderRadius:'10%',borderColor:'darkgreen',padding:'5%',margin:'5%'}} >
				<Form onSubmit={onSubmit} >

					<Grid container justifyContent={'center'}>
						<Grid >
							<div className='login-title' ><img alt='' src={logo} width='80%' /><div style={{textAlign:'center',margin:'10%'}}>Log In</div></div>
						</Grid>
					</Grid>
					<Grid container >
							<Form.Group controlId='formName'>
								<Form.Control
									type='email'
									name='email'
									placeholder='Email'
									size='lg'
									required
									className='purchase-input'
									onChange={onChangeHandler}
								/>
							</Form.Group>
						</Grid>
					<Grid container >
						<Grid
							className={classes.formCol1}
						>
							<Form.Group controlId='formPassword'>
								<Form.Control
									className='col-xs-4 purchase-input '
									type='password'
									name='password'
									placeholder='Password'
									size='lg'
									required
									onChange={onChangeHandler}
								/>
							</Form.Group>
						</Grid>
					</Grid>
					<Grid container justifyContent={'center'}>
							<Link to={ROUTES.REGISTER} style={{textDecorationLine: 'underline'}}>Register Store</Link>
					</Grid>
					<Grid container justifyContent={'center'}>
							<Button
								type='submit'
								className='login-button'
								variant='contained'
							    size='large'
							>
								Log In
							</Button>
					</Grid>
				</Form>
			</div>
	);
};

export default Login;
