export enum ROUTES {
	LOGIN = '/login',
	REPORTS = '/reports',
	MENU = '/menu',
	SALES = '/sales',
	PURCHASE = '/purchase',
	SELLGST = '/sellGST',
	PURCHASEGST = '/purchaseGST',
	GSTRECLAIM = '/GSTReclaim',
	GOODSRETURNREPORTS = '/GoodsReturn',
	STAFFATTENDENCE = '/StaffAttendence',
	SETTINGS = '/Settings',
	CUSTOMERS = '/Customers',
	SUPPLIERS = '/Suppliers',
	MARKETINSIGHTS = '/MarketInsights',
	FINANCIALREPORTS = '/FinancialReports',
	CUSTOMERPREVIOUSTRANSACTION = '/CustomerPreviousTransaction/:customerId',
	PREVIOUSTRANSACTIONSSELL = '/PreviousTransactionsSell/:itemId',
	PREVIOUSTRANSACTIONSPURCHASE = '/PreviousTransactionsPurchase/:itemId',
	SUPPLIERPREVIOUSTRANSACTION = '/SupplierPreviousTransaction/:supplierId',
	INVENTORY = '/Inventory',
	NOTIFICATIONS = '/Notifications',
	HOMEPAGE = '/HomePage',
	REGISTER = '/register',
	FORGOT = '/forgot',
	CONFIRM = '/confirm',
	HOME = '/home',
	ROOT = '/',
}
