import React, { lazy, Suspense, Fragment, useEffect } from 'react';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';
import { LoadingGif } from './components/elements/Loading';
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser, logout } from './actions/register';
import PrivateRoute from './utils/routing/PrivateRoute';
import './components/styles/main.css';
import { ROUTES } from './utils/routing/routes';
import { TOKEN_KEY } from './utils/consts';

const Alerts = lazy(() => import('./components/elements/Alerts'));
const Login = lazy(() => import('./components/AuthPages/Login'));
const Register = lazy(() => import('./components/AuthPages/Register'));

const Reports = lazy(() => import('./components/Pages/Reports'));
const Menu = lazy(() => import('./components/Pages/Menu'));
const HomePage = lazy(() => import('./components/Pages/HomePage'));
const Sales = lazy(() => import('./components/Pages/Sell/Sales'));
const Purchase = lazy(() => import('./components/Pages/Purchase/Purchase'));
const SellGST = lazy(() => import('./components/Pages/SellGST'));
const PurchaseGST = lazy(() => import('./components/Pages/PurchaseGST'));
const GSTReclaim = lazy(() => import('./components/Pages/GSTReclaim'));
const StaffAttendence = lazy(
	() => import('./components/Pages/StaffAttendence')
);
const PreviousTransactionsSell = lazy(
	() => import('./components/Pages/PreviousTransactionsSell')
);
const PreviousTransactionsPurchase = lazy(
	() => import('./components/Pages/PreviousTransactionsPurchase')
);
const SupplierPreviousTransaction = lazy(
	() => import('./components/Pages/SupplierPreviousTransaction')
);
const Settings = lazy(() => import('./components/Pages/Settings'));
const Customers = lazy(() => import('./components/Pages/Customers'));
const Suppliers = lazy(() => import('./components/Pages/Suppliers'));
const MarketInsights = lazy(() => import('./components/Pages/MarketInsights'));
const CustomerPreviousTransaction = lazy(
	() => import('./components/Pages/CustomerPreviousTransaction')
);
const FinancialReports = lazy(
	() => import('./components/Pages/FinancialReports')
);
const GoodsReturnReports = lazy(
	 () => import('./components/Pages/GoodsReturnReports')
);
const Inventory = lazy(() => import('./components/Pages/Inventory'));
const Notifications = lazy(() => import('./components/Pages/Notifications'));

const Confirm = lazy(() => import('./components/AuthPages/Confirm'));
const Forgot = lazy(() => import('./components/AuthPages/Forgot'));

if (localStorage.token) {
	setAuthToken(localStorage.token);
}

const App = () => {
	useEffect(() => {
		store.dispatch(loadUser());
		const handleInvalidToken = (e: StorageEvent) => {
			if (e.key === TOKEN_KEY && e.oldValue && !e.newValue) {
				store.dispatch(logout());
			}
		};
		window.addEventListener('storage', handleInvalidToken);
		return function cleanup() {
			window.removeEventListener('storage', handleInvalidToken);
		};
	}, []);

	return (
		<Provider store={store}>
			<Router>
				<Suspense fallback={LoadingGif}>
					<Fragment>
						<section>
							<Alerts />
							<Switch>
								<Route exact path={ROUTES.LOGIN} component={Login} />
								<Route exact path={ROUTES.REGISTER} component={Register} />
								<Route exact path={ROUTES.FORGOT} component={Forgot} />
								<PrivateRoute exact path={ROUTES.REPORTS} component={Reports} />
								<PrivateRoute exact path={ROUTES.MENU} component={Menu} />
								<PrivateRoute exact path={ROUTES.SALES} component={Sales} />
								<PrivateRoute
									exact
									path={ROUTES.NOTIFICATIONS}
									component={Notifications}
								/>
								<PrivateRoute
									exact
									path={ROUTES.PURCHASE}
									component={Purchase}
								/>

								<PrivateRoute
									exact
									path={ROUTES.CUSTOMERPREVIOUSTRANSACTION}
									component={CustomerPreviousTransaction}
								/>
								<PrivateRoute
									exact
									path={ROUTES.SUPPLIERPREVIOUSTRANSACTION}
									component={SupplierPreviousTransaction}
								/>
								<PrivateRoute
									exact
									path={ROUTES.PREVIOUSTRANSACTIONSSELL}
									component={PreviousTransactionsSell}
								/>
								<PrivateRoute
									exact
									path={ROUTES.PREVIOUSTRANSACTIONSPURCHASE}
									component={PreviousTransactionsPurchase}
								/>
								<PrivateRoute
									exact
									path={ROUTES.PURCHASEGST}
									component={PurchaseGST}
								/>
								<PrivateRoute exact path={ROUTES.SELLGST} component={SellGST} />
								<PrivateRoute
									exact
									path={ROUTES.GSTRECLAIM}
									component={GSTReclaim}
								/>
								<PrivateRoute
									exact
									path={ROUTES.STAFFATTENDENCE}
									component={StaffAttendence}
								/>
								<PrivateRoute
									exact
									path={ROUTES.SETTINGS}
									component={Settings}
								/>
								<PrivateRoute
									exact
									path={ROUTES.CUSTOMERS}
									component={Customers}
								/>
								<PrivateRoute
									exact
									path={ROUTES.SUPPLIERS}
									component={Suppliers}
								/>
								<PrivateRoute
									exact
									path={ROUTES.MARKETINSIGHTS}
									component={MarketInsights}
								/>
								<PrivateRoute
									exact
									path={ROUTES.FINANCIALREPORTS}
									component={FinancialReports}
								/>
								<PrivateRoute
									exact
									path={ROUTES.GOODSRETURNREPORTS}
									component={GoodsReturnReports}
								/>
								<PrivateRoute
									exact
									path={ROUTES.INVENTORY}
									component={Inventory}
								/>

								<Route
									exact
									path={`${ROUTES.CONFIRM}/:token`}
									component={Confirm}
								/>

								<PrivateRoute exact path={ROUTES.ROOT} component={Login} />

								<PrivateRoute exact path={ROUTES.HOME} component={HomePage} />
								<Redirect from='*' to={ROUTES.ROOT} />
							</Switch>
						</section>
					</Fragment>
				</Suspense>
			</Router>
		</Provider>
	);
};

export default App;
