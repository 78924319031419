export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const LOAD_FAIL = 'LOAD_FAIL';
export const LOGOUT = 'LOGOUT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAIL = 'VERIFICATION_FAIL';
export const CHANGE_STORE = 'CHANGE_STORE'

export const TOKEN_KEY = 'token';
export const ALERT_CLEAR_TIME = 4000;
export enum ALERT_TYPE {
	DANGER = 'danger',
	SUCCESS = 'success',
}

export const SET_MENU = 'SET_MENU';
export interface Action {
	type: string;
	payload: any;
}

export interface ReminderDetails {
	"enabled": Boolean,
	"repeatRule": string,
	"isRepetition": Boolean,
	"message": string,
	"storeId": string,
	"startTime": Date,
	"__v": number,
	"_id": string,
	"reminderId": string,
}

export interface StoreItemDetails {
	[x: string]: any;
	"batches": [
		{
			"_id": string,
			"cgst": number,
			"sgst": number,
			"igst": number,
			"cess": number,
			"location": string,
			"isDamaged": Boolean,
			"isReturn": Boolean,
			"purchaseDate": Date,
			"packagingDate": Date,
			"expiryDate": Date,
			"store": string,
			"quantity": number,
			"costPrice": number,
			"sellingPrice": number,
			"mrp": number,
			"__v": number
		}
	],
	"itemData": {
		"_id": string,
		"notifThreshold": number,
		"hsnCode": string,
		"shelfLife": number,
		"name": string,
		"company": string,
		"category": string,
		"weightVolume": string,
		"unit": string,
		"barcode": string,
		"__v": number
	},
	"quantity"?: number
	"rate"?: number
}

export interface SellItemsDetails {
	"batches": [
		{
			"_id": string,
			"cgst": number,
			"sgst": number,
			"igst": number,
			"cess": number,
			"location": string,
			"isDamaged": Boolean,
			"isReturn": Boolean,
			"purchaseDate": Date,
			"packagingDate": Date,
			"expiryDate": Date,
			"store": string,
			"quantity": number,
			"costPrice": number,
			"sellingPrice": number,
			"mrp": number,
			"__v": number
		}
	],
	"itemData": {
		"_id": string,
		"notifThreshold": number,
		"hsnCode": string,
		"shelfLife": number,
		"name": string,
		"company": string,
		"category": string,
		"weightVolume": string,
		"unit": string,
		"barcode": string,
		"__v": number
	},
	"quantity"?: number,
	"rate"?: number
}

export interface AccessLevel {
	"accessType": string,
	"isAccess": boolean
}

export interface StaffDetails {
	"userName": string,
	"store": string,
	"email": string,
	"name": string,
	"password": string,
	"phoneNumber": string,
	"address": string,
	"accessLevels": [AccessLevel],
	"attendance":any,
	"_id": string,
	"__v": number
}

export interface ProductsBillProps {
	batch: string,
	item: string,
	quantity: number,
	accepted: boolean
	}

export interface AddBillProps {
		store: string,customer: string,products: ProductsBillProps[],
		status: string,discount: number,deliveryCharge: number,paymentReceived: number,
		totalAmount: number, modeOfPayment: string, deliveryDuration: number, billingDuration: number
	}
export interface GetSellBillProps {
		store: string,startDate: Date,endDate: Date
	}

export const FETCH_SUPPLIERS = 'FETCH_SUPPLIERS';
export const FETCH_SUPPLIERS_FAIL = 'FETCH_SUPPLIERS_FAIL'; 
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const ADD_SUPPLIER_FAIL = ' ADD_SUPPLIER_FAIL';
export const UPDATE_SUPPLIER = 'UPDATE_SUPPLIER';
export const UPDATE_SUPPLIER_FAIL = ' UPDATE_SUPPLIER_FAIL';
export const PREVIOUS_SUPPLIER_TRANSACTION = 'PREVIOUS_SUPPLIER_TRANSACTION';
export const PREVIOUS_SUPPLIER_TRANSACTION_FAIL = 'PREVIOUS_SUPPLIER_TRANSACTION_FAIL';

export const FETCH_CUSTOMERS = 'FETCH_CUSTOMERS';
export const FETCH_CUSTOMERS_FAIL = 'FETCH_CUSTOMERS_FAIL';
export const ADD_CUSTOMER = 'ADD_CUSTOMER';
export const ADD_CUSTOMER_FAIL = ' ADD_CUSTOMER_FAIL';
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL';
export const PREVIOUS_CUSTOMER_TRANSACTION = 'PREVIOUS_CUSTOMER_TRANSACTION';
export const PREVIOUS_CUSTOMER_TRANSACTION_FAIL = 'PREVIOUS_CUSTOMER_TRANSACTION_FAIL';

export const ADD_STAFF = 'ADD_STAFF';
export const ADD_STAFF_FAIL = ' ADD_STAFF_FAIL';
export const FETCH_STAFF_DETAILS = ' FETCH_STAFF_DETAILS';
export const FETCH_STAFF_FAIL = 'FETCH_STAFF_FAIL';
export const UPDATE_ATTENDANCE = 'UPDATE_ATTENDANCE';
export const  UPDATE_ATTENDANCE_FAIL = ' UPDATE_ATTENDANCE_FAIL';

export const ADD_ITEM = 'ADD_ITEM';
export const ADD_ITEM_FAIL = ' ADD_ITEM_FAIL';
export const FETCH_ITEMS = 'FETCH_ITEMS';
export const FETCH_ITEMS_FAIL = 'FETCH_ITEMS_FAIL';
export const DELETE_BATCH = 'DELETE_BATCH';
export const DELETE_BATCH_FAIL = 'DELETE_BATCH_FAIL';
export const ADD_BATCH = 'ADD_BATCH';
export const ADD_BATCH_FAIL = 'ADD_BATCH_FAIL';
export const UPDATE_BATCH = 'UPDATE_BATCH';
export const UPDATE_BATCH_FAIL  = 'UPDATE_BATCH_FAIL';


export const ADD_REMINDER = 'ADD_REMINDER';
export const ADD_REMINDER_FAIL = ' ADD_REMINDER_FAIL';
export const DELETE_REMINDER = 'DELETE_REMINDER';
export const DELETE_REMINDER_FAIL = ' DELETE_REMINDER_FAIL';
export const UPDATE_REMINDER = 'UPDATE_REMINDER';
export const UPDATE_REMINDER_FAIL = 'UPDATE_REMINDER_FAIL';

export const ADD_SELL_BILL = 'ADD_SELL_BILL';
export const ADD_SELL_BILL_FAIL = 'ADD_SELL_BILL_FAIL';
export const FETCH_SELL_BILL = 'FETCH_SELL_BILL'
export const FETCH_SELL_BILL_FAIL = 'FETCH_SELL_BILL_FAIL'

export const DAY_TOTALS = 'DAY_TOTALS';
export const DAY_TOTALS_FAIL = 'DAY_TOTALS_FAIL';

export const FETCH_SELLGST = 'FETCH_SELLGST';
export const FETCH_PURCHASEGST = 'FETCH_PURCHASEGST';
export const FETCH_GSTRECLAIM = 'FETCH_GSTRECLAIM';
export const FETCH_STAFFATTENDANCE = 'FETCH_STAFFATTENDANCE';
export const FETCH_FINANCIALREPORT = 'FETCH_FINANCIALREPORT';
export const FETCH_SELLGST_FAIL = 'FETCH_SELLGST_FAIL';
export const FETCH_PURCHASEGST_FAIL = 'FETCH_PURCHASEGST_FAIL';
export const FETCH_GSTRECLAIM_FAIL = 'FETCH_GSTRECLAIM_FAIL';
export const FETCH_STAFFATTENDANCE_FAIL = 'FETCH_STAFFATTENDANCE_FAIL';
export const FETCH_FINANCIALREPORT_FAIL = 'FETCH_FINANCIALREPORT_FAIL';

export const FETCH_TRENDING_ITEMS = 'FETCH_TRENDING_ITEMS';
export const  FETCH_TRENDING_ITEMS_FAIL = ' FETCH_TRENDING_ITEMS_FAIL';
export const FETCH_BESTROI_ITEMS = 'FETCH_BESTROI_ITEMS';
export const  FETCH_BESTROI_ITEMS_FAIL = ' FETCH_BESTROI_ITEMS_FAIL';

export const brandedProductCategories = [
	"Snacks",
	"Home Care",
	"Personal Care",
	"Health Care",
	"Bakery",
	"Breakfast",
	"Chocolates",
	"Stationery",
	"Dairy",
	"Cereals & Pulses",
	"WB Government",
	"Frozen Items",
	"Sauce",
	"Cooking",
	"Baby Care",
	"Drinks & Beverages"
  ];

export const rawMaterialCategories = ["Vegetables-Raw","Cereals-Raw","Pulses-Raw","Spices-Raw","Dry Fruits-Raw","Local Items"];

export const units = ['Kgs', 'Pieces'];

