import {
	Action,
	ADD_STAFF,
	ADD_STAFF_FAIL,
	FETCH_STAFF_DETAILS,
	FETCH_STAFF_FAIL,
	StaffDetails,
	UPDATE_ATTENDANCE,
	UPDATE_ATTENDANCE_FAIL,
} from '../utils/consts';

const initialState: StaffDetails[] = [];

const staffReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case ADD_STAFF:
			return [...state, payload];
		case ADD_STAFF_FAIL:
		case FETCH_STAFF_DETAILS:
			return payload.staff;
		case FETCH_STAFF_FAIL:
		case UPDATE_ATTENDANCE:
			let staffs = [...state];
			let index = staffs.findIndex(ele => ele._id === payload.staff._id);
			staffs[index].attendance = payload.staff.attendance;
			return staffs;
		case UPDATE_ATTENDANCE_FAIL:
		default:
			return state;
	}
};

export default staffReducer;
