import {
	Action,
	ADD_CUSTOMER,
	ADD_CUSTOMER_FAIL,
	UPDATE_CUSTOMER,
	UPDATE_CUSTOMER_FAIL,
	PREVIOUS_CUSTOMER_TRANSACTION,
	PREVIOUS_CUSTOMER_TRANSACTION_FAIL,
	FETCH_CUSTOMERS,
	FETCH_CUSTOMERS_FAIL,
} from '../utils/consts';

export interface CustomerDetails {
	bills: any[];
	ledger: number;
	_id: string;
	name: string;
	store: string;
	address: string;
	phoneNumber: string;
	email: string;
	ordersPerWeek: number;
	creditEligibility: number;
	gstNumber: string;
	__v: number;
}

const initialState: CustomerDetails[] = [];

const customerReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case ADD_CUSTOMER:
			return [...state, payload];
		case ADD_CUSTOMER_FAIL:
		case UPDATE_CUSTOMER:
			let updatedCustomers = [...state];
			updatedCustomers.forEach((customer, index) => {
				if (customer._id === payload.customer._id)
					updatedCustomers[index] = payload.customer;
			});
			return updatedCustomers;
		case UPDATE_CUSTOMER_FAIL:
		case PREVIOUS_CUSTOMER_TRANSACTION:
			let customers = [...state];
			customers.forEach((customer, index) => {
				if (customer._id === payload.customer._id)
					customers[index] = payload.customer;
			});
			return customers;
		case PREVIOUS_CUSTOMER_TRANSACTION_FAIL:
		case FETCH_CUSTOMERS:
			console.log(payload);

			return payload;
		case FETCH_CUSTOMERS_FAIL:
		default:
			return state;
	}
};

export default customerReducer;
