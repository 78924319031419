import {
	Action,
	ADD_SUPPLIER,
	ADD_SUPPLIER_FAIL,
	UPDATE_SUPPLIER,
	UPDATE_SUPPLIER_FAIL,
	PREVIOUS_SUPPLIER_TRANSACTION,
	PREVIOUS_SUPPLIER_TRANSACTION_FAIL,
	FETCH_SUPPLIERS,
	FETCH_SUPPLIERS_FAIL,
} from '../utils/consts';

export interface SupplierPayload {
	name?: string;
	email?: string;
	gstNumber?: string;
	address?: string;
	phoneNumber?: string;
	_id: string;
}

const initialState: SupplierPayload[] = [];

const supplierReducer = (state = initialState, action: Action) => {
	const { type, payload } = action;
	switch (type) {
		case ADD_SUPPLIER:
			return [...state, payload];
		case ADD_SUPPLIER_FAIL:
		case UPDATE_SUPPLIER:
			let updatedSuppliers = [...state];
			updatedSuppliers.forEach((supplier, index) => {
				if (supplier._id === payload.supplier._id)
					updatedSuppliers[index] = payload.supplier;
			});
			return updatedSuppliers;
		case UPDATE_SUPPLIER_FAIL:
		case PREVIOUS_SUPPLIER_TRANSACTION:
			return state;
		case PREVIOUS_SUPPLIER_TRANSACTION_FAIL:
		case FETCH_SUPPLIERS:
			return payload;
		case FETCH_SUPPLIERS_FAIL:
		default:
			return state;
	}
};

export default supplierReducer;
